<template>
  <el-dialog
    title="邀请用户"
    :visible.sync="visible"
    class="invite-dialog"
    :append-to-body="true"
    width="468px"
    @close="$emit('close')"
  >
    <!--    <div>-->
    <!--      <el-popover-->
    <!--        ref="elPopover"-->
    <!--        v-model="visibleCum"-->
    <!--        popper-class="follow-array-popover"-->
    <!--        width="408"-->
    <!--        placement="bottom-start"-->
    <!--        trigger="click"-->
    <!--      >-->
    <!--        <div-->
    <!--          :class="['follow-popover-content',-->
    <!--                   followOptions.length<=6 ? 'no-scroll-no-more': '']"-->
    <!--        >-->
    <!--          <infinite-scroll-->
    <!--            :load-more-fun="fetchData"-->
    <!--            :loading.sync="loading"-->
    <!--            :page.sync="params.currentPage"-->
    <!--            :page-size="params.pageSize"-->
    <!--            :total="total"-->
    <!--            :empty-text="emptyText"-->
    <!--            class="stock-view-container"-->
    <!--          >-->
    <!--            <div class="stocks-list">-->
    <!--              <template v-for="(item) in followOptions">-->
    <!--                <div-->
    <!--                  :key="item.id"-->
    <!--                  class="stock-option-item"-->
    <!--                  @click="userSelect(item)"-->
    <!--                >-->
    <!--                  <span>{{ item.name }}</span>-->
    <!--                </div>-->
    <!--              </template>-->
    <!--            </div>-->
    <!--          </infinite-scroll>-->
    <!--        </div>-->
    <!--        <div slot="reference">-->
    <!--          <el-input-->
    <!--            v-model="searchRequire"-->
    <!--            class="popover-search"-->
    <!--            size="mini"-->
    <!--            autofocus-->
    <!--            clearable-->
    <!--            placeholder="搜索互相关注的好友"-->
    <!--            @input="handleFilter"-->
    <!--            @clear="clearRequire"-->
    <!--          >-->
    <!--            <i-->
    <!--              slot="prefix"-->
    <!--              class="icon iconfont icon-v_search-small"-->
    <!--            />-->
    <!--          </el-input>-->
    <!--        </div>-->
    <!--      </el-popover>-->
    <!--    </div>-->
    <div>
      <infinite-scroll
        :load-more-fun="getMutualFollow"
        :loading.sync="loadingAll"
        :page.sync="paramsAll.page"
        :page-size="paramsAll.pageSize"
        :total="totalAll"
        empty-text="暂无互关好友，去关注更多"
        class="mutual-follow-container"
      >
        <div class="mutual-follow-list">
          <el-checkbox-group v-model="checkList">
            <template v-for="(item) in followArray">
              <el-checkbox
                :key="item.followedUid"
                :label="item.followedUid"
              >
                <div class="follow">
                  <logo-panel
                    :info="item"
                    class="avatar"
                  />
                  <div>
                    <div class="follow-name">
                      {{ item.name }}
                    </div>
                    <div class="follow-identity">
                      {{ item.intro || '-' }}
                    </div>
                  </div>
                </div>
              </el-checkbox>
            </template>
          </el-checkbox-group>
        </div>
      </infinite-scroll>
    </div>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="mini"
        @click="$emit('close')"
      >取消</el-button>
      <el-button
        v-sensor:click="{
          $element_id: 'live_invitation',
          consumer_id: profile.uid
        }"
        type="primary"
        size="mini"
        placeholder="请输入简短描述"
        @click="handleInvite"
      >确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex';
import InfiniteScroll from '@/views/components/InfiniteScroll';
import { getMutualFollowUser, invitedOther } from '@/services/user';
import LogoPanel from '@/views/components/LogoPanel';

export default {
  name: 'InviteDialog',
  components: {
    InfiniteScroll,
    LogoPanel,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    roomId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      // searchRequire: '',
      // showContent: false, // 是否显示搜索结果
      // followOptions: [],
      // loading: false,
      // popVisible: false,
      // params: {
      //   page: 0,
      //   pageSize: 10,
      //   name: '',
      // },
      // total: 0,
      // enterKey: false,

      checkList: [],
      loadingAll: false,
      paramsAll: {
        page: 0,
        pageSize: 7,
        liveRoomId: this.roomId,
      },
      totalAll: 0,
      followArray: [],
    };
  },
  computed: {
    ...mapState('user', ['profile']),
  },

  methods: {
    async handleInvite() {
      if (this.checkList.length === 0) {
        this.$emit('close');
        return;
      }
      try {
        await invitedOther({
          liveRoomId: this.roomId,
          invitediIds: this.checkList,
        });
        this.$message.success('已发送邀请');
        this.$emit('close');
        //  不清空数据：关闭dialog 数据会被保留，邀请后已进入的用户会仍在数据中
        //  清空数据：关闭dialog再打开 不会重新请求
        // this.paramsAll.page = 0;
        // this.checkList = [];
        // this.followArray = [];
      } catch (error) {
        //
      }
    },
    async getMutualFollow() {
      this.loadingAll = true;
      let params = {};

      const originalTotal = this.totalAll;
      const toNext = () => {
        params = {
          ...this.paramsAll,
          page: this.paramsAll.page + 1,
        };
        this.totalAll = originalTotal;
      };
      toNext();
      try {
        const res = await getMutualFollowUser(params);
        // eslint-disable-next-line
        this.loadingAll = false;
        if (res.data?.length === 0) return;
        this.paramsAll.page += 1;
        this.totalAll = res.totalCount;
        this.followArray = this.followArray.concat(res.data);
      } catch (error) {
        //
      }
    },
  },
};
</script>

<style scoped lang="scss">
.invite-dialog {
  .el-dialog {
    width: 468px !important;
  }

  /deep/ {
    .el-dialog__body {
      padding: 0;
      height: 342px;
      min-height: 342px;
    }

    .scroll-no-more {
      display: none;
    }

    .el-checkbox {
      position: relative;
    }

    .el-checkbox__input {
      position: absolute;
      top: 13px;
    }

    .el-checkbox__label {
      padding-left: 38px;
    }

    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #3e74ca;
      border-color: #3e74ca;
    }

    .el-checkbox__input.is-focus .el-checkbox__inner {
      border-color: #3e74ca;
    }
  }

  .infinite-scroll {
    height: 342px;
    padding-bottom: 24px;
  }
}

.mutual-follow-list {
  padding: 0 30px;

  /deep/ {
    //.el-checkbox-group {
    //  height: 70px;
    //}
    .el-checkbox {
      width: 100%;
      margin-right: 0;
      margin-top: 24px;
    }
  }
}
///deep/.el-checkbox__inner {
//  top: -13px;
//}
.follow {
  display: flex;

  img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }
}

.follow-name {
  font-family: PingFangSC-Medium;
  font-size: 14px;
  color: #333b44;
  line-height: 20px;
  //&:hover {
  //  color: #3E74CA;
  //}
}

.follow-identity {
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #666b71;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: 2px;
  width: 314px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
<style lang="scss">
.invite-dialog {
  .el-dialog {
    width: 468px;
  }
}

.follow-array-popover {
  height: 230px;
  padding: 8px 0 0;

  .follow-popover-content {
    height: 100%;

    .scroll-no-more {
      padding: 12px 8px 12px;
    }
  }

  .no-scroll-no-more {
    .scroll-no-more {
      display: none;
    }
  }
}
</style>
